.circular-progress-root {
  position: absolute;
  opacity: 0.6;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: grey;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
