body {
  background-color: #2d2b2b;
}

#root .chat {
  position: absolute;
  display: fixed;
  background-color: #2d2b2b;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
